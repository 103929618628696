import Vue from 'vue'
import VueRouter from 'vue-router'
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index/index.vue"),
    redirect: '/home',
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/index.vue"),
        meta: {
          title: "首页",
        },
      },
      {
        path: "/role",
        name: "role",
        component: () => import("@/views/role/index.vue"),
        meta: {
          title: "角色列表",
        },
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/user/index.vue"),
        meta: {
          title: "账号列表",
        },
      },
      {
        path: "/franchisee",
        name: "franchisee",
        component: () => import("@/views/franchisee/index.vue"),
        meta: {
          title: "加盟商列表",
        },
      },
      {
        path: "/areaPoint",
        name: "areaPoint",
        component: () => import("@/views/areaPoint/index.vue"),
        meta: {
          title: "区域点位列表",
        },
      },
      {
        path: "/equipment",
        name: "equipment",
        component: () => import("@/views/equipment/index.vue"),
        meta: {
          title: "设备列表",
        },
      },
       {
        path: "/vminfo",
        name: "vmInfo",
        component: () => import("@/views/equipment/vmInfo.vue"),
        meta: {
          title: "设备详情",
        },
      },
      {
        path: "/warn",
        name: "vmWarn",
        component: () => import("@/views/equipment/vmWarn.vue"),
        meta: {
          title: "设备报警记录",
        },
      },
      {
        path: "/goods",
        name: "goods",
        component: () => import("@/views/goods/index.vue"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "/regionPrice",
        name: "regionPrice",
        component: () => import("@/views/operationStrategy/regionPrice.vue"),
        meta: {
          title: "区域零售价格调整",
        },
      },
      {
        path: "/patnerPrice",
        name: "patnerPrice",
        component: () => import("@/views/operationStrategy/patnerPrice.vue"),
        meta: {
          title: "加盟商调价权限",
        },
      },
      {
        path: "/vmtempControl",
        name: "vmtempControl",
        component: () => import("@/views/operationStrategy/vmtempControl.vue"),
        meta: {
          title: "设备温控策略",
        },
      },


      {
        path: "/vmSaleReport",
        name: "vmSaleReport",
        component: () => import("@/views/reportForm/vmSaleReport.vue"),
        meta: {
          title: "机器销售统计报表",
        },
      },
      {
        path: "/goodSaleReport",
        name: "goodSaleReport",
        component: () => import("@/views/reportForm/goodSaleReport.vue"),
        meta: {
          title: "商品销售统计报表",
        },
      },
      {
        path: "/vmgoodSale",
        name: "vmgoodSale",
        component: () => import("@/views/reportForm/vmgoodSale.vue"),
        meta: {
          title: "机器商品销售查询",
        },
      },
      {
        path: "/refundReport",
        name: "refundReport",
        component: () => import("@/views/reportForm/refundReport.vue"),
        meta: {
          title: "退款统计报表",
        },
      },
      {
        path: "/refund",
        name: "refund",
        component: () => import("@/views/reportForm/refund.vue"),
        meta: {
          title: "退款查询",
        },
      },
      {
        path: "/replenishReport",
        name: "replenishReport",
        component: () => import("@/views/reportForm/replenishReport.vue"),
        meta: {
          title: "补撤货统计报表",
        },
      },
      {
        path: "/replenish",
        name: "replenish",
        component: () => import("@/views/reportForm/replenish.vue"),
        meta: {
          title: "补撤货查询",
        },
      },
      {
        path: "/stockReport",
        name: "stockReport",
        component: () => import("@/views/reportForm/stockReport.vue"),
        meta: {
          title: "库存统计报表",
        },
      },
      {
        path: "/stock",
        name: "stock",
        component: () => import("@/views/reportForm/stock.vue"),
        meta: {
          title: "库存查询",
        },
      },
      {
        path: "/vmfailReport",
        name: "vmfailReport",
        component: () => import("@/views/reportForm/vmfailReport.vue"),
        meta: {
          title: "设备故障统计",
        },
      },
      {
        path: "/vmfail",
        name: "vmfail",
        component: () => import("@/views/reportForm/vmfail.vue"),
        meta: {
          title: "设备故障查询",
        },
      },
      {
        path: "/runTime",
        name: "runTime",
        component: () => import("@/views/reportForm/runTime.vue"),
        meta: {
          title: "运行时长统计",
        },
      },
      {
        path: "/advertPlaylog",
        name: "advertPlaylog",
        component: () => import("@/views/reportForm/advertPlaylog.vue"),
        meta: {
          title: "广告播放日志",
        },
      },
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  }
]

const router = new VueRouter({
  routes
})

export default router
