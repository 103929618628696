import Cookies from "js-cookie";
const state = {
  // 凭证
  token: Cookies.get("token") || null,
  // 用户信息
  userInfo: JSON.parse(localStorage.getItem("userInfo")) || null,
};

const getters = {};

const mutations = {
  SET_USERINFO: (state, info) => {
    if (info) {
      localStorage.setItem("userInfo", JSON.stringify(info))
    } else {
      localStorage.removeItem("userInfo")
    }
    state.userInfo = info;
  },
  SET_TOKEN: (state, token) => {
    if (token) {
      Cookies.set("token", token, { expires: 1 })
    } else {
      Cookies.remove("token")
    }
    state.token = token;
  },
};
const actions = {

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
