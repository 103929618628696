import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import axios from "axios";
import moment from "moment";
import * as echarts from "echarts";
import './permission';
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.filter("dateformat", function (dataStr, pattern = "YYYY-MM-DD") {
  if (dataStr) {
    return moment(dataStr).format(pattern);
  } else {
    return dataStr;
  }
});
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
