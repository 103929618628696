import router from "./router";
import store from "./store";

router.beforeEach((to, from, next) => {
    let token = store.getters.token;
    if (token) {
        // 有用户信息
        if (to.path === "/login") {
            next({ path: "/" });
        } else {
            next();
        }
    } else {
        if (to.path === "/login") {
            next()
        } else {
            next({ path: "/login" });
        }
    }
});
